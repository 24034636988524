import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { Form, Button, Col, Row, Container } from 'react-bootstrap';

const StructureVerification = () => {
  const [formData, setFormData] = useState({
    schoolName: '',
    schoolType: '',
    numClassrooms: '',
    buildingMaterial: '',
    yearConstruction: '',
    buildingApproval: null,
    buildingPictures: null,
    buildingGeolocation: '',
    numFloors: '',
    buildingCondition: '',
    fireSafety: '',
    emergencyExits: '',
    accessibilityFeatures: '',
    totalArea: '',
    additionalComments: '',
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send form data to server
    console.log(formData);
  };

  return (
    <Container className="p-4">
      <center>
        <img className='logo' src='./logo.png' alt="Logo" />
        <h2 className="mb-4">Structural Integrity Certification Form</h2>
      </center>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formSchoolName">
              <Form.Label>School Name</Form.Label>
              <Form.Control
                type="text"
                name="schoolName"
                value={formData.schoolName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formSchoolType">
              <Form.Label>School Type</Form.Label>
              <Form.Control
                as="select"
                name="schoolType"
                value={formData.schoolType}
                onChange={handleChange}
                required
              >
                <option value="">Select School Type</option>
                <option value="primary">Primary</option>
                <option value="secondary">Secondary</option>
                <option value="tertiary">Tertiary</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formNumClassrooms">
              <Form.Label>Number of Classrooms</Form.Label>
              <Form.Control
                type="number"
                name="numClassrooms"
                value={formData.numClassrooms}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formBuildingMaterial">
              <Form.Label>Material Used in Building</Form.Label>
              <Form.Control
                as="select"
                name="buildingMaterial"
                value={formData.buildingMaterial}
                onChange={handleChange}
                required
              >
                <option value="">Select Building Material</option>
                <option value="concrete">Concrete</option>
                <option value="wood">Wood</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formYearConstruction">
              <Form.Label>Year of Construction</Form.Label>
              <Form.Control
                type="number"
                name="yearConstruction"
                value={formData.yearConstruction}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formBuildingApproval">
              <Form.Label>Upload Building Approval Certificates</Form.Label>
              <Form.Control
                type="file"
                name="buildingApproval"
                onChange={handleChange}
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formBuildingPictures">
              <Form.Label>Upload Pictures of Building</Form.Label>
              <Form.Control
                type="file"
                name="buildingPictures"
                onChange={handleChange}
                accept=".jpg,.jpeg,.png"
                multiple
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formBuildingGeolocation">
              <Form.Label>Geolocation of Building</Form.Label>
              <Form.Control
                type="text"
                name="buildingGeolocation"
                value={formData.buildingGeolocation}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formNumFloors">
              <Form.Label>Number of Floors</Form.Label>
              <Form.Control
                type="number"
                name="numFloors"
                value={formData.numFloors}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formBuildingCondition">
              <Form.Label>Building Condition</Form.Label>
              <Form.Control
                as="select"
                name="buildingCondition"
                value={formData.buildingCondition}
                onChange={handleChange}
                required
              >
                <option value="">Select Building Condition</option>
                <option value="new">New</option>
                <option value="good">Good</option>
                <option value="fair">Fair</option>
                <option value="poor">Poor</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formFireSafety">
              <Form.Label>Fire Safety Equipment</Form.Label>
              <Form.Control
                as="select"
                name="fireSafety"
                value={formData.fireSafety}
                onChange={handleChange}
                required
              >
                <option value="">Select an Option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formEmergencyExits">
              <Form.Label>Emergency Exits</Form.Label>
              <Form.Control
                type="number"
                name="emergencyExits"
                value={formData.emergencyExits}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formAccessibilityFeatures">
              <Form.Label>Accessibility Features</Form.Label>
              <Form.Control
                type="text"
                name="accessibilityFeatures"
                value={formData.accessibilityFeatures}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formTotalArea">
              <Form.Label>Total Area of Building (sq meters)</Form.Label>
              <Form.Control
                type="number"
                name="totalArea"
                value={formData.totalArea}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="mb-3">
            <Form.Group controlId="formAdditionalComments">
              <Form.Label>Additional Comments</Form.Label>
              <Form.Control
                as="textarea"
                name="additionalComments"
                value={formData.additionalComments}
                onChange={handleChange}
                rows="4"
                cols="50"
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default StructureVerification;
