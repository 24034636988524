import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { Form, Button, Col, Row, Container } from 'react-bootstrap';

const Registration = () => {
  const [formData, setFormData] = useState({
    schoolName: '',
    location: '',
    yearOfEstablishment: '',
    typeOfSchool: '',
    typeOfCurriculum: '',
    staffCount: '',
    pupilCount: '',
    inventory: '',
    cacCertification: null,
    accreditationStatus: '',
    schoolEmail: '',
    phoneNumber: '',
    website: '',
    schoolLogo: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send form data to server
    console.log(formData);
  };

  return (
    <Container className="p-4">
        <center>
        <img className='logo' src='./logo.png' />
      <h2 className="mb-4">School Registration Form</h2>
      </center>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formSchoolName">
              <Form.Label>School Name</Form.Label>
              <Form.Control
                type="text"
                name="schoolName"
                value={formData.schoolName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formLocation">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formYearOfEstablishment">
              <Form.Label>Year of Establishment</Form.Label>
              <Form.Control
                type="number"
                name="yearOfEstablishment"
                value={formData.yearOfEstablishment}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formTypeOfSchool">
              <Form.Label>Type of School</Form.Label>
              <Form.Control
                as="select"
                name="typeOfSchool"
                value={formData.typeOfSchool}
                onChange={handleChange}
                required
              >
                <option value="">Select Type of School</option>
                <option value="Pre-school">Pre-school</option>
                <option value="Primary">Primary</option>
                <option value="Secondary">Secondary</option>
                <option value="Pre-school and Primary">Pre-school and Primary</option>
                <option value="Primary and Secondary">Primary and Secondary</option>
                <option value="Pre-school, Primary and Secondary">Pre-school, Primary and Secondary</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formTypeOfCurriculum">
              <Form.Label>Type of Curriculum in Nigeria</Form.Label>
              <Form.Control
                as="select"
                name="typeOfCurriculum"
                value={formData.typeOfCurriculum}
                onChange={handleChange}
                required
              >
                <option value="">Select Type of Curriculum</option>
                <option value="Nigerian">Nigerian</option>
                <option value="British">British</option>
                <option value="American">American</option>
                <option value="Hybrid">Hybrid</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formStaffCount">
              <Form.Label>Staff Count</Form.Label>
              <Form.Control
                type="number"
                name="staffCount"
                value={formData.staffCount}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formPupilCount">
              <Form.Label>Pupil Count</Form.Label>
              <Form.Control
                type="number"
                name="pupilCount"
                value={formData.pupilCount}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formInventory">
              <Form.Label>Inventory</Form.Label>
              <Form.Control
                type="text"
                name="inventory"
                value={formData.inventory}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formCACCertification">
              <Form.Label>CAC Certification Upload</Form.Label>
              <Form.Control
                type="file"
                name="cacCertification"
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formAccreditationStatus">
              <Form.Label>Accreditation Status</Form.Label>
              <Form.Control
                as="select"
                name="accreditationStatus"
                value={formData.accreditationStatus}
                onChange={handleChange}
                required
              >
                <option value="">Select Accreditation Status</option>
                <option value="Accredited">Accredited</option>
                <option value="Pending">Pending</option>
                <option value="Declined">Declined</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formSchoolEmail">
              <Form.Label>School Email</Form.Label>
              <Form.Control
                type="email"
                name="schoolEmail"
                value={formData.schoolEmail}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formWebsite">
              <Form.Label>Website</Form.Label>
              <Form.Control
                type="url"
                name="website"
                value={formData.website}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formSchoolLogo">
              <Form.Label>School Logo Upload</Form.Label>
              <Form.Control
                type="file"
                name="schoolLogo"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default Registration;
