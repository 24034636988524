import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { Table, Button, Container } from 'react-bootstrap';

const Approval = () => {
  const [schools, setSchools] = useState([
    { id: 1, name: 'Hillcrest School, Jos', status: 'Pending', paymentStatus: '' },
    { id: 2, name: 'Kent Academy, Jos', status: 'Pending', paymentStatus: '' },
    { id: 3, name: 'Hosanna College, Jos', status: 'Pending', paymentStatus: '' },
    { id: 4, name: 'Infant Jesus Academy', status: 'Pending', paymentStatus: '' },
    { id: 5, name: 'Kings and Queen Academy', status: 'Pending', paymentStatus: '' },
    { id: 6, name: 'White Diamonds Academy', status: 'Pending', paymentStatus: '' },
    { id: 7, name: 'Aristocrats High School', status: 'Pending', paymentStatus: '' },
    { id: 8, name: 'Peakfield Academy', status: 'Pending', paymentStatus: '' },
    { id: 9, name: 'Fatima Girls College', status: 'Pending', paymentStatus: '' },
    { id: 10, name: 'Adonai Vine School', status: 'Pending', paymentStatus: '' },
  ]);

  useEffect(() => {
    setSchools(schools.map(school => ({
      ...school,
      paymentStatus: Math.random() > 0.5 ? 'Paid' : 'Pending'
    })));
  }, []);

  const handleAction = (id, newStatus) => {
    setSchools(schools.map(school => 
      school.id === id ? { ...school, status: newStatus } : school
    ));
  };

  return (
    <Container className="p-4">
      <center>
        <img className='logo' src='./logo.png' alt='logo'/>
        <h2 className="mb-4">Approval Page</h2>
      </center>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>School Name</th>
            <th>Status</th>
            <th>Payment Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {schools.map((school, index) => (
            <tr key={school.id} style={{ backgroundColor: school.paymentStatus === 'Pending' ? 'yellow' : 'inherit' }}>
              <td>{index + 1}</td>
              <td>{school.name}</td>
              <td>{school.status}</td>
              <td>{school.paymentStatus}</td>
              <td>
                {school.paymentStatus !== 'Pending' && (
                  <Button 
                    variant="success" 
                    className="me-2"
                    onClick={() => handleAction(school.id, 'Approved')}
                    disabled={school.status !== 'Pending'}
                  >
                    Approve
                  </Button>
                )}
                <Button 
                  variant="danger"
                  onClick={() => handleAction(school.id, 'Declined')}
                  disabled={school.status !== 'Pending'}
                >
                  Decline
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Approval;
