import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import Registration from './Registration';
import Application from './Application';
import Approval from './Approval';
import Result from './Result';
import StructureVerification from './StructureVerification';
import Login from './Login';

const DashboardPage = () => {
  const [currentPage, setCurrentPage] = useState('registration');

  const renderPage = () => {
    switch (currentPage) {
      case 'registration':
        return <Registration />;
      case 'application':
        return <Application />;
      case 'building':
        return <StructureVerification />;
      case 'login':
          return <Login />;
      case 'approval':
        return <Approval />;
      case 'result':
        return <Result id="1" />; // Pass a sample ID for the result page
      default:
        return <Login />;
    }
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" className="mb-4">
        <Container>
          <Navbar.Brand href="#">VERIFICATION PORTAL</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            <Nav.Link onClick={() => setCurrentPage('application')}>Pin/Payments</Nav.Link>
              <Nav.Link onClick={() => setCurrentPage('registration')}>Registration</Nav.Link>
              <Nav.Link onClick={() => setCurrentPage('building')}>Structural Integrity</Nav.Link>
              <Nav.Link onClick={() => setCurrentPage('approval')}>Approval</Nav.Link>
              <Nav.Link onClick={() => setCurrentPage('login')}>Login</Nav.Link>
              
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container>
        {renderPage()}
      </Container>
    </>
  );
};

export default DashboardPage;
