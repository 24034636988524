import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import './App.css';
//import ResultChecker from './ResultChecker';
import Result from './Result';
import Registration from './Registration';
import Application from './Application';
import Approval from './Approval';
import Login from './Login';
import Home from './Home';
import StructureVerification from './StructureVerification';

function App() {
  return (
    <div className="app-container">
    
    <BrowserRouter>
      <div className='main-content'>
      <Routes>
      
      <Route path='/' element={<Home />}></Route>
      <Route path='/login' element={<Login />}></Route>
        <Route path='/registration' element={<Registration />}></Route>
        <Route path='/application' element={<Application />}></Route>
        <Route path='/approval' element={<Approval />}></Route>
        <Route path='/building' element={<StructureVerification />}></Route>
        <Route path='/result/:id' element={<Result />}></Route>

      </Routes>
      </div>
    </BrowserRouter>
    
    </div>
    
  );
}

export default App;
