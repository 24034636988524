import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { Form, Button, Col, Row, Container } from 'react-bootstrap';

const Login = () => {
  const [formData, setFormData] = useState({
    pin: '',
    serialNumber: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send form data to server
    console.log(formData);
  };

  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <div className="w-50">
        <center>
          <img className='logo mb-4' src='./logo.png' alt='Logo' />
          <h2 className="mb-4">Enter PIN and Serial Number</h2>
        </center>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formPin" className="mb-3">
            <Form.Label>PIN</Form.Label>
            <Form.Control
              type="password"
              name="pin"
              value={formData.pin}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formSerialNumber" className="mb-3">
            <Form.Label>Serial Number</Form.Label>
            <Form.Control
              type="text"
              name="serialNumber"
              value={formData.serialNumber}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="w-100">
            Submit
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default Login;
