import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { Form, Button, Col, Row, Container } from 'react-bootstrap';

const Application = () => {
  const [formData, setFormData] = useState({
  
    dateOfApplication: '',
    paymentOption: '',
    emailAddress: '',
    phoneNumber: '',
    organizationName: '',
    purposeOfVerification: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send form data to server
    console.log(formData);
  };

  return (
    <Container className="p-4">
        <center>
        <img className='logo' src='./logo.png' />
      <h2 className="mb-4">Application for Verification</h2>
      </center>
      <Form onSubmit={handleSubmit}>
        

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formDateOfApplication">
              <Form.Label>Date of Application</Form.Label>
              <Form.Control
                type="date"
                name="dateOfApplication"
                value={formData.dateOfApplication}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formPaymentOption">
              <Form.Label>Payment Option</Form.Label>
              <Form.Control
                as="select"
                name="paymentOption"
                value={formData.paymentOption}
                onChange={handleChange}
                required
              >
                <option value="">Select Payment Option</option>
                <option value="USSD">USSD</option>
                <option value="Remita">Remita</option>
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="Flutterwave">Flutterwave</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formEmailAddress">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formOrganizationName">
              <Form.Label>School Name</Form.Label>
              <Form.Control
                type="text"
                name="organizationName"
                value={formData.organizationName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="formPurposeOfVerification">
              <Form.Label>Purpose of Verification</Form.Label>
              <Form.Control
                type="text"
                name="purposeOfVerification"
                value={formData.purposeOfVerification}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
        <Col md={2}>
        <Button variant="primary" type="submit">
          Submit
        </Button>
        </Col>
        <Col md={4}>
        </Col>
        <Col md={6}>
        <img className='logo' src='./remita-logo.png' />
        <img className='logo' src='./ussd-logo.png' />
        <img className='logo' src='./flutterwave-logo.png' />
        <img className='logo' src='./bank-transfer-logo.png' />
        </Col>
        </Row>

        
      </Form>
    </Container>
  );
};

export default Application;
